<template>
<!--  <vue-apex-charts type="bar" min-width="300" max-width="100%" height="70" :options="chartOptions" :series="buildChartSeries(tr.total_sold_percentage, tr.total_unsold_percentage, tr.total_unpublished_percentage )"></vue-apex-charts>-->
  <div id="stock-inventory-report-page" class="vs-row  w-full">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'InventoryDetails'">
      <template v-slot:right-end>
<!--        <vs-button class="mt-3 py-4 px-3" color="primary"-->
<!--                   @click="openAddInventory">{{$t('AddInventory')}}-->
<!--        </vs-button>-->
      </template>
    </breadcrumb-base>
    <div v-if="stockReport" class="px-4">
      <vs-row>
        <vs-col>
          <div
            class="upload-contents upload-contents-denomination flex items-center justify-start py-3">
            <img class="sidebar-product-image flex" :src="stockReport.product_logo" :alt="stockReport.product_name">
          </div>
        </vs-col>
        <vs-col>
          <h3 class="flex py-2" >{{ $t('ProductName') }}: <strong>{{stockReport.product_name }}</strong></h3>
          <h3 class="flex py-2" >{{ $t('ProductValue') }}: <strong>{{stockReport.configuration_value | germanNumberFormat}}</strong></h3>
        </vs-col>
      </vs-row>
      <!--    Empty List State -->
      <transition name="fade">
        <div class="h-screen flex w-full bg-img" v-if="stockReport.batches.length === 0">
          <div
            class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--            <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                 class="mx-auto mb-4 max-w-full">-->
            <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
          </div>
        </div>
      </transition>
      <vs-table-modified class="flex-1-1" v-show="stockReport.batches.length > 0" ref="table" pagination
                         :max-items="20" search
                         :data="stockReport.batches"
      >
        <template slot="thead">
          <vs-th style="max-width: 72px;" >{{$t('SupplierName')}}</vs-th>
          <vs-th style="max-width: 72px;" >{{$t('Status')}}</vs-th>
          <vs-th style="max-width: 72px;" >{{$t('PinsCount')}}</vs-th>
          <vs-th >{{$t('SoldPinsCount')}}</vs-th>
          <vs-th >{{$t('UnSoldPinsCount')}}</vs-th>
<!--          <vs-th >{{$t('SoldUnSoldPinsPercentage')}}</vs-th>-->
<!--          <vs-th >{{$t('LotNo')}}</vs-th>-->
<!--          <vs-th >{{$t('LastPurchase')}}</vs-th>-->
          <vs-th >{{$t('Actions')}}</vs-th>
        </template>
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" v-if="tr.unsold_pins_count != 0">

            <vs-td>
              <p class="font-medium truncate">{{ tr.supplier_name | capitalize }}</p>
            </vs-td>

            <vs-td>
              <vs-chip :color="tr.published ? 'success' : 'danger'">
                <span>{{tr.published ? 'Published' : 'Unpublished'}}</span>
              </vs-chip>
            </vs-td>

            <vs-td>
              <p class="product-name">{{ tr.pins_count }}</p>
            </vs-td>

            <vs-td>
              <p class="product-name">{{ tr.sold_pins_count }}</p>
            </vs-td>


            <vs-td>
              <p class="product-name">{{ tr.unsold_pins_count }}</p>
            </vs-td>

<!--            <div class="vx-col" style="padding: 1.4rem 0;">-->
<!--              <vue-apex-charts type="bar" min-width="300" max-width="100%" height="70" :options="chartOptions" :series="buildChartSeries(tr.sold_pins_percentage, tr.unsold_pins_percentage)"></vue-apex-charts>-->
<!--            </div>-->

<!--            <vs-td>-->
<!--              <p class="product-name">{{ tr.batch }}</p>-->
<!--            </vs-td>-->

<!--            <vs-td>-->
<!--              <p class="product-name">{{ tr.date_of_buying | dateFormat}}</p>-->
<!--            </vs-td>-->
            <vs-td>
              <vs-button @click="updateStockBatches(tr, !tr.published)"
                         :color="tr.published ? 'danger' : 'success'">
                {{tr.published  ? 'Unpublish' : 'Publish'}}
              </vs-button>
            </vs-td>

            <template class="" slot="expand">
              <div class="plain-table w-full">
                <table>
                  <tr>
                    <th>{{$t('SNo')}}</th>
                    <th>{{$t('Pin')}}</th>
                    <th>{{$t('SoldBy')}}</th>
                    <th>{{$t('SoldById')}}</th>
                    <th>{{$t('SoldTime')}}</th>
                  </tr>
                  <tbody>
                  <tr :data="pintr" :key="pinindextr" v-for="(pintr, pinindextr) in tr.sold_pins">
                    <td>
                      {{ pinindextr + 1 }}
                    </td>
                    <td>
                      <strong>{{pintr.pin}}</strong>
                    </td>
                    <td>
                      {{ pintr.sold_by_name }}
                    </td>
                    <td>
                      {{ pintr.sold_by_id }}
                    </td>
                    <td>
                      {{ pintr.sold_by_at | dateFormat }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>

          </vs-tr>
          </tbody>
        </template>
      </vs-table-modified>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'StockInventoryDetail',
  components: {
    VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        chart: {
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          type: 'bar',
          width: '100%',
          height: 70,
          stacked: true,
          stackType: '100%',
        },
        plotOptions: {
          bar: {
            startingShape: 'rounded',
            endingShape: 'rounded',
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: undefined,
        },
        xaxis: {
          labels: {
            show: false,
          },

          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },

        tooltip: {
          y: {
            formatter(val) {
              return `${val}%`
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          floating: true,
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 0,
        },
      },
      errorFetching: false,
      stockReport: null,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Inventory', i18n: 'Inventory', url: '/inventory/view' },
        { title: 'Inventory Details', i18n: 'InventoryDetails', active: true },
      ],
    }
  },
  props: {
    configId: {
      type: Number | String,
      default: '',
    },
  },
  methods: {
    openAddInventory() {
      this.$router.push({ name: 'inventory-add' })
    },
    buildChartSeries(sold, unsold) {
      return [
        {
          name: 'Sold',
          data: [sold],
        }, {
          name: 'Unsold',
          data: [unsold],
        },
      ]
    },

    updateStockBatches(batch, publish) {
      const payload = {
        'configuration_id': this.stockReport.configuration_id,
        'batch': batch.batch,
        'publish': publish == true ? 1 : 0,
      }
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchStockReports', { payload })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.stockReport = data.stock_report
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchStockBatches() {
      const payload = {
        'configuration_id': this.configId,
      }
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchStockReports', { payload })
        .then((data) => {
          this.stockReport = data.stock_report
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchStockBatches()
  },
}
</script>

<style lang="scss">
#stock-inventory-report-page {
  .product-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }

  .sidebar-product-image {
    /*max-width: 200px;*/
    max-height: 70px;
    object-fit: cover;
  }

  .plain-table {
    table {
      width: 100%;
      border-collapse: collapse;
    }
    /* Zebra striping */
    tr:nth-of-type(odd) {
      background: #eee;
    }
    th {
      background: #333;
      color: white;
      font-weight: bold;
    }
    td, th {
      padding: 6px;
      border: 1px solid #ccc;
      text-align: left;
    }
  }
}

</style>
